<template>
  <v-dialog
    persistent
    v-model="profileDialogEditUser"
    :width="screenWidth + '%'"
  >
    <div class="container-dialog">
      <close-dialog-component @closeDialog="closeDialog" />
      <p class="text-title mon-bold">
        {{ texts.buyers.dialogAddUser.textTitleEdit }}
      </p>
      <div class="mt-9">
        <v-text-field
          v-model="userName"
          type="text"
          :label="texts.buyers.dialogAddUser.textName"
          outlined
          color="#000000"
          dense
          class="global-inputs mon-regular mb-5"
          :placeholder="texts.buyers.dialogAddUser.textName"
          persistent-placeholder
        ></v-text-field>
        <v-text-field
          v-model="userLastName"
          type="text"
          :label="texts.buyers.dialogAddUser.textLastName"
          outlined
          color="#000000"
          dense
          class="global-inputs mon-regular mb-5"
          :placeholder="texts.buyers.dialogAddUser.textLastName"
          persistent-placeholder
        ></v-text-field>
        <v-text-field
          v-model="userEmail"
          type="text"
          :label="texts.buyers.dialogAddUser.textEmail"
          outlined
          color="#000000"
          dense
          class="global-inputs mon-regular mb-5"
          :placeholder="texts.buyers.dialogAddUser.textEmail"
          persistent-placeholder
        ></v-text-field>
        <v-text-field
          v-model="userPosition"
          type="text"
          :label="texts.buyers.dialogAddUser.textPosition"
          outlined
          color="#000000"
          dense
          class="global-inputs mon-regular mb-5"
          :placeholder="texts.buyers.dialogAddUser.textPosition"
          persistent-placeholder
        ></v-text-field>
        <v-text-field
          v-model="userPhone"
          @keypress="soloNumbers($event)"
          :label="texts.buyers.dialogAddUser.textPhone"
          outlined
          color="#000000"
          dense
          class="global-inputs mon-regular mb-5"
          :placeholder="texts.buyers.dialogAddUser.textPhone"
          persistent-placeholder
        ></v-text-field>
      </div>
      <div v-if="isResponsive" class="mt-6 mt-9">
        <v-btn
          elevation="0"
          @click="saveChanges"
          :loading="bLoading"
          :disabled="!validateForm"
          class="button-save mon-bold"
          style="width: 100%;"
        >
          {{ texts.buyers.dialogAddUser.buttonEdit }}
        </v-btn>
        <v-btn
          elevation="0"
          @click="closeDialog"
          class="button-cancel mon-regular mt-5 mb-2"
          style="width: 100%;"
        >
          {{ texts.buyers.dialogAddUser.buttonCancel }}
        </v-btn>
      </div>
      <div v-else class="mt-6 display-flex align-items-center mt-9">
        <v-btn
          elevation="0"
          @click="closeDialog"
          class="button-cancel mon-regular"
        >
          {{ texts.buyers.dialogAddUser.buttonCancel }}
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          elevation="0"
          @click="saveChanges"
          :loading="bLoading"
          :disabled="!validateForm"
          class="button-save mon-bold"
        >
          {{ texts.buyers.dialogAddUser.buttonEdit }}
        </v-btn>
      </div>
    </div>
  </v-dialog>
</template>

<script>
export default {
  name: "ProfileEditUserLayout",
  data() {
    //VARIABLES
    return {
      bShowCurrentPassword: false,
      bShowNewPassword: false,
      bShowConfirmNewPassword: false,
      bLoading: false,
      currentPassword: "",
      newPassword: "",
      confirmNewPassword: "",
      texts: "",
      userName: "",
      userLastName: "",
      userEmail: "",
      userPosition: "",
      userPhone: "",
      screenWidth: 0,
      isResponsive: false,
    };
  },
  beforeMount() {
    this.texts = FILE.profile[this.selectLanguage];
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize: function () {
      if (window.innerWidth >= 960) {
        this.screenWidth = 30;
      } else {
        this.screenWidth = 100;
      }

      if (window.innerWidth < 960) {
        this.isResponsive = true;
      } else {
        this.isResponsive = false;
      }
    },
    closeDialog: function () {
      this.$store.commit("setProfileDialogEditUser", {
        active: false,
        arr: [],
      });
      this.userName = "";
      this.userLastName = "";
      this.userEmail = "";
      this.userPosition = "";
      this.userPhone = "";
    },
    saveChanges: function () {
      this.bLoading = true;

      const payload = {
        sName: this.userName,
        sLastname: this.userLastName,
        sEmail: this.userEmail,
        sPositionName: this.userPosition,
        sPhoneNumber: this.userPhone,
      };

      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
      };

      DB.put(
        `${URI}/api/v1/${this.selectLanguage}/customers/enterprises/${this.$store.state.sEnterpriseId}/employees/${this.$store.state.profileArrEditUser.sCustomerId}`,
        payload,
        config
      )
        .then((response) => {
          this.bLoading = false;

          this.mixSuccess(response.data.message);
          this.closeDialog();
          this.$store.commit("refresher", true);
        })
        .catch((error) => {
          this.bLoading = false;

          this.mixError(error.response.data.message, error.response.status);
        });
    },
    soloNumbers: function (evt) {
      if (
        (evt.which != 8 && evt.which != 0 && evt.which < 48) ||
        evt.which > 57
      ) {
        evt.preventDefault();
      }
    },
  },
  computed: {
    // return create contact dialog state
    profileDialogEditUser() {
      return this.$store.state.profileDialogEditUser;
    },
    selectLanguage() {
      return this.$store.state.language;
    },
    validateForm: function () {
      return (
        this.userName !== "" &&
        this.userLastName !== "" &&
        this.userEmail !== "" &&
        this.userPosition !== "" &&
        this.userPhone !== ""
      );
    },
  },
  watch: {
    profileDialogEditUser() {
      if (this.profileDialogEditUser) {
        this.texts = FILE.profile[this.selectLanguage];
        this.userName = this.$store.state.profileArrEditUser.sName;
        this.userLastName = this.$store.state.profileArrEditUser.sLastname;
        this.userEmail = this.$store.state.profileArrEditUser.sEmail;
        this.userPosition = this.$store.state.profileArrEditUser.sPositionName;
        this.userPhone = this.$store.state.profileArrEditUser.sPhoneNumber;
      }
    },
    selectLanguage: function () {
      if (this.selectLanguage) {
        this.texts = FILE.profile[this.selectLanguage];
      }
    },
  },
};
</script>

<style scoped>
.button-save:disabled {
  background: gray !important;
  cursor: not-allowed;
  pointer-events: visible;
}

.button-cancel {
  width: 45%;
  height: 40px !important;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #283c4d;
  border-radius: 10px;
  opacity: 1;
  text-align: center;
  font-size: 14px;
  letter-spacing: 0px;
  color: #283c4d;
  background-color: #ffffff !important;
  text-transform: initial;
}

.button-save {
  width: 45%;
  height: 40px !important;
  background: transparent linear-gradient(257deg, #ffc556 0%, #fffc81 100%) 0%
    0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  text-align: center;
  font-size: 14px;
  letter-spacing: 0px;
  color: #283c4d;
  text-transform: initial;
}

.container-dialog {
  width: 100%;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #e5e5e5;
  border-radius: 6px;
  opacity: 1;
  padding: 25px;
  position: relative;
}

.text-title {
  text-align: center;
  letter-spacing: 0px;
  color: #283c4d;
  opacity: 1;
  font-size: 25px;
}
</style>